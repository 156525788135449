import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'
import myTableBalai from './table_balai'
import {
  mainAPI
} from "@/API/mainAPI";

let urutantableSIMURP = 0
let urutantableIPDMIP = 0
let urutantableDOISP = 0

export default {
  data() {
    return {
      search: '',
      loadingTb: false,
      isActive: [],
      options: null,
      summary: [],

      listStatker: [],
      listBalai: [],
      listGiat: [],
      listEvent: [],
      selectSatker: null,
      tbGiatName:[],
      isShow: false,
      tbData: [],
      headers: [],
      tbTotal: [],
      arahan: [],
      tbData_balai: [],
      headers_balai: [],
      tbTotal_balai: [],
      tbTotalPusat_balai: [],
      giatHover: [],
      search_balai: [],
      loadingTb_balai: [],
      arahan_balai: [],
      extraHead: [],
      extraHead_balai: [],
      listRegisterSet: [],
      data_color: ["#00C5DC", "#F4516C", "#FFB822", "#8859E0", "#0C5484", "#66BB6A", "#00838F", "#e57373"],

    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {

    // this.G_GetAny('sakti/event').then(res => {
    //   let resData = res.data.content

    //   let listData = [];
    //   let arrSub = {};
    //   let setDefault = null

    //   resData.forEach(v => {

    //     if (v.asDefault) {
    //       setDefault = v.saktiEventId
    //     }

    //     arrSub = {};

    //     arrSub['text'] = v.saktiEventNm;
    //     arrSub['value'] = v.saktiEventId;

    //     listData.push(arrSub);
    //   });

    //   this.listEvent = listData;

    //   // this.$refs.event.setValue(setDefault)
    //   // this.getSummary()

    // })


    this.getSummary()
  },
  components: {
    myTable,
    myTableBalai
  },
  computed: {

  },
  methods: {
   
    getSummary() {

      this.isShow = true
      this.loadingTb = true
      this.G_GetAny('adk/resume/summary/loan-perkegiatan/' + this.$route.query.eventId).then(res => {

        this.tbGiatName = res.data.nmpembinas;

        if (res) {
          this.loadingTb = false
          this.tbData = res.data.content

          let tmpHead = res.data.kdgiats

          this.headers = [{
                text: 'No',
                value: 'noRow',
                sortable: false,
                width: "1%"
              },
              {
                text: 'ID Register',
                value: 'registerId'
              },
              {
                text: 'Register',
                value: 'registerNm'
              },
              {
                text: 'Total',
                value: 'total'
              },
            ],

            tmpHead.forEach(v => {
              this.headers.push({
                text: v,
                value: 'pagu[' + v + ']',
                align: 'right'
              })
            });

          // let pagu {};
          let tmpTotal = res.data.paguTotal;

          this.tbTotal = tmpTotal
          this.arahan = res.data.arahanTotal

          this.extraHead = tmpHead

        } else {
          this.tbData = []
        }


      })

      $('#listKegiatan').html(``)

      ///////// per balai

      this.G_GetAny('adk/resume/summary/loan-perkegiatan/' + this.$route.query.eventId + '/list-register').then(res => {
        this.listRegisterSet = res.data.content

        this.$nextTick(() => {
          let tmpListReg = res.data.content
          

          if (tmpListReg.length) {

            this.getAllRef(tmpListReg)


          }

        });

      })




    },
    cariData() {
      this.getSummary();
    },

    async getAllRef(tmpListReg) {


      await tmpListReg.forEach(vTmp => {
        this.G_GetAny(`adk/resume/summary/loan-perkegiatan/${this.$route.query.eventId}/${vTmp.registerId}`).then(resTB => {

          let lr = resTB.data
          let extraHead = resTB.data.kdgiats
          let content = resTB.data.content
          let footTotal = resTB.data.paguTotal
          let arahanTotal = resTB.data.arahanTotal
          let ArahanPusatTotal = resTB.data.arahanPusatTotal
          let giatName = resTB.data.nmpembinas

          let tmpHead = ``
          let tmpGiatName = ``
          let tmpBody = ``
          let tmpBodyExtra = ``
          let tmpFootTotal = ``
          let tmpFootArahanPusat = ``
          let tmpFootArahanPusatTotal = ``

          for (const [key, v] of Object.entries(giatName)) {
            tmpGiatName += `<th class="text-right">${v}</th>`
          }

          extraHead.forEach(eh => {
            tmpHead += `<th class="text-right text-uppercase">${eh}</th>`
          });


          for (const [key, v] of Object.entries(footTotal)) {

            if (v > ArahanPusatTotal[key]) {
              tmpFootTotal += `<td class="text-right" style="font-size:12px;background:#C62828;">${this.G_numFormat(v)}</td>`
            } else if (v < ArahanPusatTotal[key]) {
              tmpFootTotal += `<td class="text-right" style="font-size:12px;background:#FFEE58;color: #000;">${this.G_numFormat(v)}</td>`

            } else {
              tmpFootTotal += `<td class="text-right" style="font-size:12px;">${this.G_numFormat(v)}</td>`
            }

          }

          for (const [key, v] of Object.entries(arahanTotal)) {
            tmpFootArahanPusat += `<td class="text-right">${this.G_numFormat(v)}</td>`
          }

          for (const [key, v] of Object.entries(ArahanPusatTotal)) {
            tmpFootArahanPusatTotal += `<td class="text-right">${this.G_numFormat(v)}</td>`
          }

          let setFootTotal = `
              <tr style="background: #585858;color: #fff;font-weight: 500;">
                <td colspan="4" style="font-size:12px;">Total</td>
                ${tmpFootTotal}
              </tr>
          `;

          let setFootArahanPusat = `
            <tr style="color: #151515;font-weight: 600;">
                <td colspan="4" style="font-size:12px;">Total Arahan</td>
                ${tmpFootArahanPusat} 
              </tr>
          `;

          let setFootArahanPusatTotal = `
            <tr style="color: #151515;font-weight: 600;">
                <td colspan="4" style="font-size:12px;">Total Arahan Pusat</td>
                ${tmpFootArahanPusatTotal}
              </tr>
          `;





          content.forEach((c,k) => {

            tmpBodyExtra = ``
            let pagu = c.pagu
            for (const [key, v] of Object.entries(pagu)) {
              tmpBodyExtra += `<td class="text-right">${this.G_numFormat(v)}</td>`
            }




            tmpBody += `
            <tr>
              <td>${k+1}</td>
              <td>${c.kdsatker}</td>
              <td>${c.nmsatker}</td>
              <td class="text-right">${this.G_numFormat(c.total)}</td>
              ${tmpBodyExtra}
            </tr>`
          });



          $('#listKegiatan').append(`
            <hr  id="${lr.registerId}" class="mt-10 mb-10" style="border: 1px dashed #d5d5d5;">
            <h6 class="mb-4 mt-0" style="border-left:5px solid #00796b;padding-left:20px;">${lr.registerNm}</h6>
            <div class="table-responsive-lg tableFixHead scrollStyle scrollStyle-v" style="cursor: grab;max-height: 50vh !important;">
                <table class="table paket zn-table">
                    <thead>
                        <tr style="background: #00796b;color: #fff;">
                            <th rowspan="3" class="text-center text-uppercase">No</th>
                            <th rowspan="3" class="text-left text-uppercase">Kode Satker</th>
                            <th rowspan="3" class="text-left text-uppercase">Nama Satker</th>
                            <th rowspan="3" class="text-right text-uppercase">Total</th>
                            <th colspan="${extraHead.length}" class="text-center text-uppercase">Kegiatan</th>
                        </tr>
                        <tr style="background: #00796b;color: #fff;top: 42px;">
                          ${tmpHead}
                        </tr>
                        <tr style="background: #00796b;color: #fff;top: 84px;">
                        ${tmpGiatName}
                        </tr>
                    </thead>
                    <tbody>
                      ${tmpBody}
                      ${setFootTotal}
                      ${setFootArahanPusatTotal}
                      ${setFootArahanPusat}
                        
                    </tbody>
                </table>
            </div>
            
            `);

        })

      });



    }

  },
  computed: {
    // setNoUrutSIMURP(){
    //   console.log(urutantableSIMURP);
    //   urutantableSIMURP++ 
    //   return urutantableSIMURP
    // },
  }
}