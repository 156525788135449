var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tbData,"single-expand":_vm.singleExpand,"search":_vm.search,"loading":_vm.loadingTb,"hide-default-header":"","loading-text":"Sedang Memuat Data...","disable-pagination":"","hide-default-footer":"","expanded":_vm.expanded,"item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"3"}},[_vm._v("NO")]),_c('th',{attrs:{"rowspan":"3"}},[_vm._v("ID REGISTER")]),_c('th',{attrs:{"rowspan":"3"}},[_vm._v("REGISTER")]),_c('th',{attrs:{"rowspan":"3"}},[_vm._v("TOTAL")]),_c('th',{staticClass:"text-center",attrs:{"colspan":Object.keys(_vm.tbGiatName).length}},[_vm._v("KEGIATAN")])]),_c('tr',[_vm._l((props.headers),function(head,k){return [(head.text.toUpperCase() != 'NO' 
            && head.text.toUpperCase() != 'ID REGISTER' 
            && head.text.toUpperCase() != 'REGISTER'
            && head.text.toUpperCase() != 'TOTAL' )?_c('th',{key:k,staticClass:"text-center"},[_vm._v(_vm._s(head.text.toUpperCase()))]):_vm._e()]})],2),_c('tr',[_vm._l((_vm.tbGiatName),function(head,k){return [_c('th',{key:k,staticClass:"text-center"},[_vm._v(_vm._s(head))])]})],2)])]}},{key:"item.noRow",fn:function(ref){
            var item = ref.item;
return [(item.registerId)?_c('div',[_vm._v(" "+_vm._s(_vm.tbData.map(function(x) {return x.registerId; }).indexOf(item.registerId)+1)+" ")]):_vm._e()]}},{key:"item.total",fn:function(ref){
            var item = ref.item;
return [(item.total > 0)?_c('div',{staticClass:"p-2 text-right"},[_vm._v(" "+_vm._s(_vm.G_numFormat(item.total))+" ")]):_vm._e()]}},{key:"item.registerNm",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-left btn btn-hover-light-primary",on:{"click":function($event){return _vm.toView(item.registerId)}}},[_vm._v(_vm._s(item.registerNm)+" ")])]}},{key:"item.registerId",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-left btn btn-hover-light-primary",on:{"click":function($event){return _vm.toView(item.registerId)}}},[_vm._v(_vm._s(item.registerId)+" ")])]}},_vm._l((_vm.extraHead),function(slot,i){return {key:("item.pagu[" + slot + "]"),fn:function(ref){
            var item = ref.item;
return [(item.pagu[slot] > item.arahan[slot])?_c('div',{key:i,staticClass:"text-right zn-label-pagu-lebih"},[_vm._v(_vm._s(_vm.G_numFormat(item.pagu[slot])))]):(item.pagu[slot] < item.arahan[slot])?_c('div',{key:i,staticClass:"text-right zn-label-pagu-kurang"},[_vm._v(_vm._s(_vm.G_numFormat(item.pagu[slot])))]):_c('div',{key:i,staticClass:"text-right",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.G_numFormat(item.pagu[slot])))])]}}})],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticStyle:{"background":"#039be5","color":"#fff","font-weight":"500"}},[_c('td',{staticStyle:{"font-size":"12px"},attrs:{"colspan":"4"}},[_vm._v("Total")]),_vm._l((_vm.tbTotal),function(slot,i){return [(slot > _vm.arahan[i])?_c('td',{key:i,staticClass:"text-right",staticStyle:{"font-size":"12px","background":"#C62828"}},[_vm._v(_vm._s(_vm.G_numFormat(slot)))]):(slot < _vm.arahan[i])?_c('td',{key:i,staticClass:"text-right",staticStyle:{"font-size":"12px","background":"#FFEE58","color":"#000"}},[_vm._v(_vm._s(_vm.G_numFormat(slot)))]):_c('td',{key:i,staticClass:"text-right",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.G_numFormat(slot)))])]})],2),_c('tr',{staticStyle:{"background":"#b4e1f7","color":"#151515","font-weight":"600"}},[_c('td',{staticStyle:{"font-size":"12px"},attrs:{"colspan":"4"}},[_vm._v("Arahan Total")]),_vm._l((_vm.arahan),function(slot,i){return [_c('td',{key:i,staticClass:"text-right",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.G_numFormat(slot)))])]})],2)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }