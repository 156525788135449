<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
            :loading="loadingTb" hide-default-header loading-text="Sedang Memuat Data..." disable-pagination  hide-default-footer :expanded.sync="expanded" item-key="id">

            
            <template v-slot:header="{ props }">
        <thead>
        <tr>
            <th rowspan="3">NO</th>
            <th rowspan="3">ID REGISTER</th>
            <th rowspan="3">REGISTER</th>
            <th rowspan="3">TOTAL</th>
            <th :colspan="Object.keys(tbGiatName).length" class="text-center">KEGIATAN</th>
        </tr>
        <tr>
            <template v-for="(head,k) in props.headers">
                
                <th class="text-center" :key="k" v-if="head.text.toUpperCase() != 'NO' 
                && head.text.toUpperCase() != 'ID REGISTER' 
                && head.text.toUpperCase() != 'REGISTER'
                && head.text.toUpperCase() != 'TOTAL' ">{{ head.text.toUpperCase() }}</th>

            </template>
        </tr>
        <tr>
            <template v-for="(head,k) in tbGiatName">
                <th :key="k" class="text-center">{{head}}</th>

            </template>
        </tr>
         
        </thead>

       
      </template>
            
            <template v-slot:item.noRow="{ item }">
                <div v-if="item.registerId">
                    {{tbData.map(function(x) {return x.registerId; }).indexOf(item.registerId)+1}}

                </div>
            </template>
            <template v-slot:item.total="{item}">
                <div v-if="item.total > 0" class="p-2 text-right">
                    {{G_numFormat(item.total)}}
                </div>
            </template>

            <template v-slot:item.registerNm="{item}">
                <div class="text-left btn btn-hover-light-primary" @click="toView(item.registerId)">{{item.registerNm}} </div>
            </template>

             <template v-slot:item.registerId="{item}">
                <div class="text-left btn btn-hover-light-primary" @click="toView(item.registerId)">{{item.registerId}} </div>
            </template>

          <template v-for="(slot,i) in extraHead" v-slot:[`item.pagu[${slot}]`]="{ item }">
                <div v-if="item.pagu[slot] > item.arahan[slot]" :key="i" class="text-right zn-label-pagu-lebih" >{{G_numFormat(item.pagu[slot])}}</div>
                <div v-else-if="item.pagu[slot] < item.arahan[slot]" :key="i" class="text-right zn-label-pagu-kurang">{{G_numFormat(item.pagu[slot])}}</div>
                <div v-else :key="i" class="text-right" style="font-size:12px;">{{ G_numFormat(item.pagu[slot]) }}</div>

            </template>


            <template slot="body.append">
                <tr style="background: #039be5;color: #fff;font-weight: 500;">
                    <td colspan="4" style="font-size:12px;">Total</td>
                    <template v-for="(slot,i) in tbTotal">
                        <td v-if="slot > arahan[i]" :key="i" class="text-right" style="font-size:12px;background:#C62828;">{{ G_numFormat(slot) }}</td>
                        <td v-else-if="slot < arahan[i]" :key="i" class="text-right" style="font-size:12px;background:#FFEE58;color: #000;">{{ G_numFormat(slot) }}</td>
                        <td v-else :key="i" class="text-right" style="font-size:12px;">{{ G_numFormat(slot) }}</td>

                    </template>

                </tr>

                 <tr style="background: #b4e1f7;color: #151515;font-weight: 600;">
                    <td colspan="4" style="font-size:12px;">Arahan Total</td>
                    <template v-for="(slot,i) in arahan">
                        <td :key="i" class="text-right" style="font-size:12px;">{{ G_numFormat(slot) }}</td>
                    </template>

                </tr>
            </template>

        </v-data-table>



    </div>
</template>
<script>
    export default {
        data() {
            return {
                expanded: [],
                singleExpand: false,

            }
        },
        mounted() {

        },
        methods: {
             toView(id){
                const el = document.getElementById(id);
                el.scrollIntoView({behavior: "smooth"});
                },
            sumField(key) {
                // sum data in give key (property)
                return this.tbData.reduce((a, b) => a + (b[key] || 0), 0)
            },

            getTable() {
                this.$emit('getTable');
            },
            edit(id) {
                this.$emit('edit', id);
            },
            hapus(satkerId, kdgiat) {
                this.$emit('hapus', satkerId, kdgiat);
            },


        },

        props: ['tbData', 'headers', 'search', 'loadingTb', 'tbTotal', 'tb_etc','arahan','extraHead','tbGiatName'],
    }
</script>